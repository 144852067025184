import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_sowa.jpg';
import ishimizu from '../images/map/ishimizu.png';
import ishimizuImg from '../images/map/ishimizuImg.jpg';
// import ishimizuTop from '../images/ob/ob_ishimizu_top.jpg';
// import * as ROUTES from '../src/constants/routes';

class IshimizuPage extends React.Component {

  render() {
    return (
      <Fragment>
        <title>地方部員インタビュー　石水鴻|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">地方部員インタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'black', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">3年 楚和</h4>
          <div className="mng-text">
          國學院大學久我山高校の入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'black'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <h4 className="vertical-timeline-element-subtitle">3年 石水鴻</h4>
          <div className="ob-text">
          はい。きっかけは、中学生時代のクラブチームのコーチが久我山高校を勧めてくれた事です。実は、クラブチームのコーチの息子さんも久我山高校出身ということで、お話を聞かせてもらう中で興味を持ちました。そして何より、大学進学も考えた時に、学力のレベルも高いというのが、魅力的でした。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
            部活面での決め手はありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          そうですね。自分のプレイスタイルと久我山のプレイスタイルが似ていて、自分に合ってると思ったのが決め手ですね。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          確かに、石水さんのプレイスタイルは、久我山バスケ部にマッチしていますよね！では、次の質問に移ります。久我山高校へ入学してよかったことを教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          そうですね。学習面から言わせてもらうとカリキュラムがしっかりしてるので、自身の学力が確実に向上したことです。部活面で言うと、短時間での練習となるので、考える力、工夫する力がついたと思います。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          学校面のカリキュラムはしっかりしていると私も思います。では、続いて久我山高校バスケ部の雰囲気や印象を教えてください。
          </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          入学前に練習に参加させてもらった時、非常にフレンドリーなチームだなと思い、親しみやすい印象でした。入部後も、大きなギャップはなく、みんな仲良く、かつ互いに刺激し合えるいい環境だと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={ishimizuImg} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          確かに、仲の良さもあり、練習の時はそれぞれ真剣に取り組むなどメリハリはありますよね！ちなみに、石水さんは、出身は新潟県だと思いますが、どうやって東京にある久我山高校へ通学しているのですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          実は、久我山高校への入学を決心しており、予定では東京の郊外からでも通うつもりでした。しかし、たまたま親の転勤時期が重なり、家族と東京で暮らす事になったので、運良く東京から通えるようになりました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          それは、すごい偶然の重なりですね！東京に来る上で心配だったことを教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          圧倒的に電車ですね！（笑）電車の量はもちろんのこと、人の量も多くてびっくりしました！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          新潟県では電車での移動は、主流ではなかったのですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          電車よりバス、車の方が主流でしたね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          なるほど。そうなんですね。知らなかったです（笑）方言など言葉に関しては不安とかありましたか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          そこに関しては、全く不安はありませんでした。基本的に、新潟県でも標準語だったので、多少の違いはあったとしても、全然気になりませんでした。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          新潟県と東京って意外に変わらないんですね！（笑）
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          では最後に、久我山高校バスケ部に興味を持っている中学生に向けてメッセージを下さい！
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishimizu} />}
        >
          <div className="ob-text">
          久我山高校は、学力と部活の両方のレベルが高い学校です。加えて、それを自分なりのペースで出来、クラス内でも部内でも他の学生と楽しく交流できます。一方で、その分、壁にぶつかる事も多く、苦労する事が多いのも事実です。だからこそ、将来やりたいことに対して全力で、高いレベルで実践して行きたい人には、是非久我山高校バスケ部に入部して頂き、勉学面・部活面で挑戦してもらいたいです！全力で挑戦できる環境がここにはあります！
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <IshimizuPage />
  </Layout>
);
